<template>
  <div class="Client font">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0 font">
        <banner
          title="RETAILERS"
          :breadcrumb="[
            {
              label: 'Dashboard',
            },
            { label: 'Retailers' },
          ]"
        >
        </banner>
        <div v-if="loading == true">
          <Spinner />
        </div>

        <div class="row font" style="margin-top: -45px">
          <div class="col-xl-12 col-md-12 col-sm-12">
            <div class="d-xl-none d-md-none d-block pt-1"></div>
            <div
              class="card custom-card"
       
            >
              <div class="card-body">
                <!-- start list area  -->
              



                <div class="row">
                  <div class="col text font">
                    <h4><strong style="color: #00364f; font-weight: bolder">Retailers</strong></h4>
                  </div>
                  <div class="col text-end cus-p-1">
                   
                <button type="button" data-bs-toggle="modal" data-bs-target="#new-task-modal" title="Filter"
                      class="btn text-white btn-sm" style="background-color: #00364f"> <strong> <font-awesome-icon
                          icon="filter" /></strong></button>
                          &nbsp;

                  </div>
                    <div class="col-xl-1 col-md-1 d-md-block d-xl-block d-none cus-p-1" >

                    <select
                      style="height: 30px; padding: 5px 5px 5px 5px"
                      class="form-select"
                      @change="changePageNo"
                      aria-label="Default select example"
                    >
         
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </div>



                <div class="row ">
                  <div class="d-md-none d-xl-none d-block col-3 text font cus-p-1">

                    <select
                      style="height: 30px; padding: 5px 5px 5px 5px"
                      class="form-select"
                      @change="changePageNo"
                      aria-label="Default select example"
                    >
                    <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>

                    </select>
                  </div>
               
                </div>
                <!-- start list area  -->


                <div class="row">
               
                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                   
                  >
                    <div class="container-fluid table-scroll">
                      <table class="table table-hover table-sm">
                        <tr class="text tr-head table-wrapper-scroll-y rounded-circle">
                          <th>&nbsp;&nbsp;+&nbsp;&nbsp;</th>
                          <th>Code</th>
                          <th >Retailer</th>
                          <th>Distributer</th>
                          <th>Phone</th>
                          <th>Email</th>
                          <th>Package</th>
                          <th>Status</th>
                          <!-- <th>Completed</th> -->
                          <th class="text-end">Action</th>
                        </tr>

                        <tbody>
                          <tr
                            style="color: #00364f"
                            v-for="(retailer, index) in retailers"
                            :key="index"
                          >
                            <td>
                              <img
                                style="width: 30px; height: 30px"
                                :src="profilePhotoFinder(retailer)"
                                alt=""
                              />
                            </td>
                            <td>{{ retailer.code }}</td>
                            <td class="text-truncate">{{ retailer.name }}</td>
                            <td>
                              <span v-if="retailer.distributer">
                          <strong>
                            {{ retailer.distributer.name }}
                            </strong>   
<div>
<small>  {{ retailer.distributer.code }}</small>
</div>
                              </span>
  

                            </td>
                            <td>{{ retailer.phone }}</td>
                            <td>{{ retailer.email }}</td>
                            <td class="text-center text-truncate">
                              <button
                                type="button"
                                @click="getRetailerPackages(retailer.retailer_package)"
                                data-bs-toggle="modal"
                                data-bs-target="#retailerPackages"
                                class="btn text-white btn-sm"
                                style="
                                  background-color: rgb(242 19 1);
                                  padding: 5px 7px 5px 7px;
                                "
                              >
                                <b>
                                  <font-awesome-icon icon="link" />
                                </b>
                              </button>
                            </td>
                            <td>
                              <p
                                v-if="retailer.loginStatus == 'true'"
                                class="text-white btn btn-sm"
                                style="
                          
                                  background-color: green;
                                  box-shadow: 2px 2px 5px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding: 5px 15px 5px 15px;
                                  margin-bottom: 5px;
                                "
                              >
                                <strong>Active</strong>
                              </p>
                              <p
                                v-else
                                class="text-white btn btn-sm"
                                style="
                           
                                  background-color: red;
                                  box-shadow: 2px 2px 5px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding: 5px 10px 5px 10px;
                                  margin-bottom: 5px;
                                "
                              >
                                <strong>InActive</strong>
                              </p>
                            </td>
                            <!-- <td>--------</td> -->
                            <td class="text-end">
                              <div
                                class="btn-group btn-group-sm"
                                role="group"
                                aria-label="..."
                              >
                                <router-link
                                  class="btn btn-sm text-white"
                                  :to="`/master-distributer/retailers-view/${retailer.id}`"
                                  style="
                                    background-color: #00364f;
                                    padding: 5px 4.5px 5px 4.5px;
                                  "
                                >
                                  <font-awesome-icon icon="eye" />
                                </router-link>

                                <!-- <button data-bs-toggle="modal"
                                                                    data-bs-target="#Retailers"
                                                                    @click="valueAssignRetailer(retailer)" type="button"
                                                                    class="btn btn-sm btn-success"
                                                                    style="padding:5px 4.5px 5px 4.5px;">
                                                                    <font-awesome-icon icon="edit" />
                                                                </button> -->
                                <!-- <button type="button" class="btn btn-sm text-white"
                                                                    @click="retailerkycValueAsign(retailer)"
                                                                    data-bs-toggle="modal" data-bs-target="#kycModal"
                                                                    style="background-color:blue;padding:5px 4.5px 5px 4.5px;">
                                                                    <i class="fa fa-user" aria-hidden="true"></i>
                                                                </button> -->
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- end list area -->
              </div>
       
              <div class="card-footer">
                    <div class="row">

                      <div class="col-md-4 col-12 text-md-start text-center cus-p-1">

                        <p class="mb-0">
                          Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.total }} entries
                        </p>
                      </div>
                      <div class="col-md-8 col-12 text-md-end text-center cus-p-1">
                        <ul class="pagination  justify-content-center">
                          <li class="page-item" @click="loadRetailers(null, 'first')">
                            <a class="page-link" href="#" aria-label="Previous">
                              <span aria-hidden="true">&laquo;</span>
                            </a>
                          </li>
                          <li class="page-item" @click="loadRetailers(null, 'prev')">
                            <a class="page-link" href="#">Previous</a>
                          </li>
                          <li class="page-item" v-if="pagination.current_page > 3"
                            @click="loadRetailers(pagination.current_page - 3)">
                            <a class="page-link" href="#">{{
                              pagination.current_page - 3
                            }}</a>
                          </li>
                          <li class="page-item" v-if="pagination.current_page > 2"
                            @click="loadRetailers(pagination.current_page - 2)">
                            <a class="page-link" href="#">{{
                              pagination.current_page - 2
                            }}</a>
                          </li>
                          <li class="page-item" v-if="pagination.current_page > 1"
                            @click="loadRetailers(pagination.current_page - 1)">
                            <a class="page-link" href="#">{{
                              pagination.current_page - 1
                            }}</a>
                          </li>
                          <li class="active page-item" @click="loadRetailers(pagination.current_page)">
                            <a class="page-link" href="#">{{ pagination.current_page }}</a>
                          </li>
                          <li class="page-item" v-if="pagination.current_page < pagination.last_page - 4"
                            @click="loadRetailers(pagination.current_page + 1)">
                            <a class="page-link" href="#">{{
                              pagination.current_page + 1
                            }}</a>
                          </li>
                          <li class="page-item" v-if="pagination.current_page < pagination.last_page - 5"
                            @click="loadRetailers(pagination.current_page + 2)">
                            <a class="page-link" href="#">{{
                              pagination.current_page + 2
                            }}</a>
                          </li>
                          <li class="page-item" v-if="pagination.current_page < pagination.last_page - 6"
                            @click="loadRetailers(pagination.current_page + 3)">
                            <a class="page-link" href="#">{{
                              pagination.current_page + 3
                            }}</a>
                          </li>
                          <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="loadRetailers( pagination.current_page + 4 )"><a href="#">{{pagination.current_page + 4}}</a></li> -->
                          <li class="page-item">
                            <a class="page-link" href="#">...</a>
                          </li>

                          <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="loadRetailers( pagination.last_page - 4 )"><a href="#">{{pagination.last_page - 4}}</a></li> -->
                          <li class="page-item" v-if="pagination.current_page < pagination.last_page - 3"
                            @click="loadRetailers(pagination.last_page - 3)">
                            <a class="page-link" href="#">{{ pagination.last_page - 3 }}</a>
                          </li>
                          <li class="page-item" v-if="pagination.current_page < pagination.last_page - 2"
                            @click="loadRetailers(pagination.last_page - 2)">
                            <a class="page-link" href="#">{{ pagination.last_page - 2 }}</a>
                          </li>
                          <li class="page-item" v-if="pagination.current_page < pagination.last_page - 1"
                            @click="loadRetailers(pagination.last_page - 1)">
                            <a class="page-link" href="#">{{ pagination.last_page - 1 }}</a>
                          </li>

                          <li class="page-item" @click="loadRetailers(null, 'next')">
                            <a class="page-link" href="#">Next</a>
                          </li>
                          <li class="page-item" @click="loadRetailers(null, 'last')">
                            <a class="page-link" href="#">&raquo;</a>
                          </li>
                        </ul>
                      </div>

                      <!-- <div class="col-md-1 col-4 text-end">
      <select
                      style="height: 30px; padding: 5px 5px 5px 5px"
                      class="form-select"
                      @change="changePageNo"
                      aria-label="Default select example"
                    >
         
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
    </div> -->
                    </div>
                  </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>

  <!-- Modal -->

  <!-- Modal -->
  <div
    class="modal fade"
    id="Retailers"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text" id="exampleModalLabel">
            <b>Retailer {{ heading }}</b>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form
            @submit.prevent="edit == 'false' ? createNewRetailer() : updateRetailer()"
          >
            <div class="container">
              <div class="row">
                <div class="col-lg-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-1">
                  <label for="image-upload">
                    <img
                      style="width: 70px; height: 70px; border-radius: 5px"
                      :src="photo"
                      alt=""
                    />
                  </label>
                  <input
                    @change="uploadImg"
                    class="input-file"
                    style="position: absolute; top: -150000px"
                    id="image-upload"
                    type="file"
                    accept="image/*"
                  />
                  <div>
                    <small><i>(Click on Image for Upoad)</i></small>
                  </div>
                </div>

                <div
                  class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12"
                  style="padding: 5px 5px 5px 5px"
                >
                  <div class="form-group">
                    <label>Code</label>
                    <input
                      type="text"
                      v-model="form.code"
                      class="form-control"
                      placeholder="Code"
                      style="height: 30px"
                    />
                  </div>
                </div>

                <div
                  class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12"
                  style="padding: 5px 5px 5px 5px"
                >
                  <div class="form-group">
                    <label>Name</label>
                    <input
                      type="text"
                      v-model="form.name"
                      class="form-control"
                      placeholder="Name"
                      style="height: 30px"
                    />
                  </div>
                </div>

                <div
                  class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12"
                  style="padding: 5px 5px 5px 5px"
                >
                  <div class="form-group">
                    <label>Phone</label>
                    <input
                      type="number"
                      v-model="form.phone"
                      class="form-control"
                      placeholder="Mobile Number"
                      style="height: 30px"
                    />
                  </div>
                </div>

                <div
                  class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12"
                  style="padding: 5px 5px 5px 5px"
                >
                  <div class="form-group">
                    <label>Email</label>
                    <input
                      type="email"
                      v-model="form.email"
                      class="form-control"
                      placeholder="Email"
                      style="height: 30px"
                    />
                  </div>
                </div>

                <div
                  class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12"
                  style="padding: 5px 5px 5px 5px"
                >
                  <div class="form-group">
                    <label>Password</label>
                    <input
                      type="password"
                      v-model="form.password"
                      class="form-control"
                      placeholder="Password"
                      style="height: 30px"
                    />
                  </div>
                </div>

                <div
                  class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                  style="padding: 5px 5px 5px 5px"
                >
                  <div class="form-group">
                    <label>Remark</label>
                    <textarea
                      class="form-control"
                      v-model="form.remark"
                      rows="3"
                      placeholder="Remark"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm text-white"
                style="background-color: #f21300"
                data-bs-dismiss="modal"
              >
                <b>Close</b>
              </button>
              <button
                type="submit"
                class="btn btn-sm text-white"
                style="background-color: #00364f"
              >
                <b>Confirm</b>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- retailer view modal -->

  <!-- Modal -->
  <div
    class="modal fade"
    id="retailerPackages"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header" style="background-color: #00364f">
          <h5 class="modal-title text-white" id="exampleModalLabel">
            <strong>Retailer Packages</strong>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <!-- sdlkfjsdlkfjdsflkjsdflsdkjfsdlfkjdsflkjsdflksdjfsdlkfjsdflkj -->
          <div class="row">
            <div class="col-xl-12 col-md-12 col-12" v-if="packages.length != 0">
              <div class="container-fluid table-scroll">
                <table class="table table-hover table-sm text" style="font-size: 14px">
                  <tr class="text tr-head table-wrapper-scroll-y rounded-circle">
                    <th class="text-truncate">Package Name</th>
                    <!-- <th class="text-truncate">On Boarding Charge</th> -->
                    <th class="text-truncate">subscriptionAmt</th>
                    <th class="text-truncate">subscriptionDate</th>
                  </tr>

                  <tbody>
                    <tr
                      style="color: #00364f"
                      v-for="(pkg, index) in packages"
                      :key="index"
                    >
                      <td>
                        <span v-if="pkg.package">{{ pkg.package.package }}</span>
                      </td>
                      <td>
                        <span v-if="pkg.package">{{ pkg.package.onBoardingCharge }}</span>
                      </td>
                      <!-- <td>
                        <span v-if="pkg">{{ pkg.subscriptionAmt }}</span>
                      </td> -->
                      <td>
                        <span v-if="pkg">{{ dateTime(pkg.subscriptionDate) }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col" v-else>
              <strong>Retailer has not activated any packages yet</strong>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-sm text-white"
            style="background-color: #f21000"
            data-bs-dismiss="modal"
          >
            <strong>Close</strong>
          </button>
          <!-- <button
              @click="saveServiceChargesAdmin"
              type="button"
              class="btn btn-sm text-white"
              style="background-color: #00364f"
            >
              <strong>Confirm</strong>
            </button> -->
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div
    class="modal fade"
    id="retailrView"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text" id="exampleModalLabel">
            <strong>Retailer Information</strong>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-6 col-12">
              <h4 style="color: #f21300"><strong>Personal Detail</strong></h4>

              <div class="row">
                <div class="row">
                  <div class="col-xl-2 col-md-2 col-sm-6 col-12">
                    <img
                      style="width: 100%"
                      :src="profilePhotoFinder(retailerData)"
                      alt=""
                    />
                  </div>
                </div>
                <div class="col mt-1">
                  <div class="text">
                    <strong>Code : </strong>
                    <span>{{ retailerData.code }}</span>
                  </div>
                  <div class="text">
                    <strong>Name : </strong>
                    <span>{{ retailerData.name }}</span>
                  </div>
                  <div class="text">
                    <strong>Phone : </strong>
                    <span>{{ retailerData.phone }}</span>
                  </div>
                  <div class="text">
                    <strong>Email : </strong>
                    <span>{{ retailerData.email }}</span>
                  </div>
                  <div class="text">
                    <strong>Address : </strong>
                    <span>{{ retailerData.address }}</span>
                  </div>
                  <div class="text">
                    <strong>State : </strong>
                    <span v-if="retailerData.state">{{ retailerData.state.name }}</span>
                  </div>
                  <div class="text">
                    <strong>Gender : </strong>
                    <span>{{ retailerData.gender }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-12 mt-1">
              <h4 style="color: #f21300">
                <strong>Retailer Kyc Detailer</strong>
              </h4>
              <div class="row">
                <div class="col">
                  <div class="text">
                    <strong>Aadhar Number : </strong>
                    <span>{{ userRetailer.aadharNo }}</span>
                  </div>

                  <div class="text">
                    <strong>Pan Number : </strong>
                    <span>{{ userRetailer.panNo }}</span>
                  </div>

                  <div class="text">
                    <strong>Account Number : </strong>
                    <span>{{ userRetailer.accountNo }}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-12 p-1">
                  <img style="width: 100%" :src="adharFrontImg" alt="" />
                  <label><strong class="text">Aadhar Front</strong></label>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-12 p-1">
                  <img style="width: 100%" :src="adharBackImg" alt="" />
                  <label><strong class="text">Aadhar Back</strong></label>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-12 p-1">
                  <img style="width: 100%" :src="panImg" alt="" />
                  <label><strong class="text">Pan</strong></label>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-12 p-1">
                  <img style="width: 100%" :src="passBookImg" alt="" />
                  <label><strong class="text">Passbook</strong></label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-sm text-white"
            style="background-color: #f21300"
            data-bs-dismiss="modal"
          >
            <b>Close</b>
          </button>
          <button
            type="submit"
            class="btn btn-sm text-white"
            style="background-color: #00364f"
          >
            <b>Confirm</b>
          </button>
        </div>
      </div>
    </div>
  </div>



  
  <!-- Right Sidebar starts -->
  <div class="modal modal-slide-in sidebar-todo-modal fade" id="new-task-modal">
    <div class="modal-dialog sidebar-sm">
      <div class="modal-content p-0">
        <form
          id="form-modal-todo"
          class="todo-modal needs-validation"
          novalidate
          onsubmit="return false"
        >
          <div
            class="modal-header align-items-center mb-1"
            style="background-color: #00364f"
          >
            <h5 class="modal-title text-white"><strong>Project Filter</strong></h5>
            <div
              class="todo-item-action d-flex align-items-center justify-content-between ms-auto text-white"
            >
              <span class="todo-item-favorite cursor-pointer me-75 text-white"
                ><i data-feather="star" class="font-medium-2"></i
              ></span>
              <i
                data-feather="x"
                class="cursor-pointer"
                data-bs-dismiss="modal"
                stroke-width="3"
                >X</i
              >
            </div>
          </div>
          <div class="modal-body flex-grow-1 pb-sm-0 pb-3">
            <ul class="list-group list-group-flush">
  <li class="list-group-item">
    <div class="form-check">
  <input  @click="valueNullFilter()" v-model="filterType" value="code" class="form-check-input" type="radio" name="flexRadioDefault" id="code">
  <label class="form-check-label" for="code">
    Code
  </label>
</div>
  </li>
  <li class="list-group-item">
    <div class="form-check">
  <input  @click="valueNullFilter()" v-model="filterType" value="name" class="form-check-input" type="radio" name="flexRadioDefault" id="name">
  <label class="form-check-label" for="name">
    Name
  </label>
</div>
  </li>
  <li class="list-group-item"><div class="form-check">
  <input  @click="valueNullFilter()" v-model="filterType" value="email" class="form-check-input" type="radio" name="flexRadioDefault" id="email">
  <label class="form-check-label" for="email">
    Email
  </label>
</div></li>
  <li class="list-group-item"><div class="form-check">
  <input @click="valueNullFilter()" v-model="filterType" value="phone" class="form-check-input" type="radio" name="flexRadioDefault" id="phone">
  <label class="form-check-label" for="phone">
    Phone
  </label>
</div></li>


<li class="list-group-item">
    <div class="form-check">
  <input @click="valueNullFilter()" v-model="filterType" value="all" class="form-check-input" type="radio" name="flexRadioDefault" id="all">
  <label class="form-check-label" for="all">
    All
  </label>
</div>
  </li>
</ul>
            <div class="pt-1 pb-1">
              <!-- <label for="exampleFormControlInput1" class="form-label">Email address</label> -->
              <!-- <input type="date" v-if="filterType == 'date' || filterType == 'dueDate' || filterType == 'completionDate'" class="form-control datepicker" v-model="filterValue" id="exampleFormControlInput1" placeholder="Enter Value"> -->
              <input
              :type="filterType == 'phone' ? 'number' : 'text'"
                v-if="filterType != 'all'"
                class="form-control"
                v-model="filterValue"
                id="exampleFormControlInput1"
                placeholder="Enter Value"
              />
              <!-- <input type="text" v-if="filterType == 'client_id'" @change="findRecordeByPanId" class="form-control" v-model="filterValue" id="exampleFormControlInput1" placeholder="Enter Value"> -->
            </div>

            <button
              @click="loadRetailers()"
              type="button"
              class="btn btn-success btn-sm"
            >
              Success
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- Right Sidebar ends -->
</template>

<script>
import Banner from "../../../components/master-distributer/comman/Banner.vue";
import Spinner from "../../../components/master-distributer/comman/Spinner.vue";
import moment from "moment";
import Form from "vform";
// import jQuery from "jquery";
// let $ = jQuery
export default {
  name: "MasterDistributerRetailers",

  components: {
    Banner,

    Spinner,
  },
  data() {
    return {
      filterValue: "",
      filterType: "",
      pagination: {
        current_page: 0,
        from: 0,
        to: 0,
        total: 0,
        last_page: 0,
        per_page: 0,
        first_page_url: null,
        last_page_url: null,
        next_page_url: null,
        prev_page_url: null,
        path: null,
      },
      per_page: 10,

      packages: [],
      loading: false,
      kycModalHeading: "Create ",
      retailerKycs: [],
      heading: "Create",
      photo: this.$store.state.placeholderImg,
      portal: "",
      retailers: [],
      allAdmins: [],
      admin_id: null,
      edit: "false",
      form: new Form({
        photo: "",
        name: "",
        email: "",
        password: "",
        phone: "",
        code: "",
        secretKey: "",
        remark: "",
      }),
      userRetailer: {},
      retailerData: {},
      adharFrontImg: this.$store.state.placeholderImg,
      adharBackImg: this.$store.state.placeholderImg,
      panImg: this.$store.state.placeholderImg,
      passBookImg: this.$store.state.placeholderImg,
      retailerKycVal: "false",
      formKyc: new Form({
        panNo: null,
        panPhoto: "",
        accountType: "",
        accountNo: null,
        passbookPhoto: "",
        aadharNo: null,
        aadharFrontPhoto: "",
        aadharBackPhoto: "",
        remark: "",
      }),
      retailer_id: null,
    };
  },
  methods: {
    valueNullFilter(){
  this.filterValue = "";
},
    changePageNo(e) {
      this.per_page = e.target.value;
      this.loadRetailers();
    },
    getRetailerPackages(pkg) {
      this.packages = pkg;
    },

    loadRetailers(page = null, pg = null) {
      console.log(page);
      console.log(pg);
      this.loading = true;
      var pageUrl = "";
      if (this.pagination.last_page_url && pg == "last") {
        pageUrl = pageUrl + this.pagination.last_page_url;
      } else if (this.pagination.first_page_url && pg == "first") {
        pageUrl = pageUrl + this.pagination.first_page_url;
      } else if (this.pagination.prev_page_url && pg == "prev") {
        pageUrl = pageUrl + this.pagination.prev_page_url;
      } else if (this.pagination.next_page_url && pg == "next") {
        pageUrl = pageUrl + this.pagination.next_page_url;
      }

      if (pg == null) {
        if (this.filterType == "all") {
          this.filterValue = "";
          this.filterType = "";
        }

        if (this.filterValue != null && this.filterType != "all") {
          if (this.filterType == "name") {
            pageUrl += `masterdistributer/retailer?per_page=${this.per_page}&searchTerm=${this.filterValue}`;
          } else {
            pageUrl += `masterdistributer/retailer?per_page=${this.per_page}&${this.filterType}=${this.filterValue}`;
          }
        } else {
          pageUrl += `masterdistributer/retailer?per_page=${this.per_page}`;
        }
      } else {
        pageUrl += `&per_page=${this.pagination.per_page}`;
      }
      if (this.table_search) {
        pageUrl += `&searchTerm=${this.table_search}`;
      }
      if (page != null) {
        pageUrl += `&page=${page}`;
      }

      this.$axios
        .get(pageUrl, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res);
          this.retailers = res.data.data.data;
          this.pagination = res.data.data;
        })
        .finally(() => (this.loading = false));
    },
    profilePhotoFinder(retailer) {
      var photo;
      if (retailer.photo != null) {
        photo =
          this.$store.state.imgUrl +
          "/retailer/" +
          retailer.id +
          "/thumbs/" +
          retailer.photo;
        return photo;
      } else {
        photo = this.$store.state.placeholderImg;
        return photo;
      }
    },
    dateTime(value) {
      console.log(value);
       if(value){
  return moment(value).format("DD-MM-YYYY");
      }
    
    },
    dateTimeWithTime(value) {
      console.log(value);
      if(value){
return moment(value).format("DD-MM-YYYY,  HH:mm a");
      }
      
    },
    loadPageTitle(){
      console.log('--------------------------');
        
        document.title =this.$store.state.masterDistributerPageTitles.retailers
    console.log(this.$store.state.masterDistributerPageTitles.retailers)
    console.log('--------------------------');
    }
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.loadRetailers();
      // this.loadDistributer()
    }
    this.loadPageTitle()
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}


.form-check-input[data-v-9cc828ca]:checked {
  border-width: 4px;
  background-color: #f9f5f5;
  border-color: #f21300;
}

.h4-heading {
  color: red;
  font-size: 1.2vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.font {
  font-family: "Poppins", sans-serif;
}

.btns {
  background-color: #00364f;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
}

.radius {
  border-radius: 23px;
}

.form-check-input:checked {
  background-color: #f21300;
  border-color: #f21300;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}
.page-item.active .page-link {
  z-index: 3;
  border-radius: 5rem;
  background-color: #00364f;
  color: #fff !important;
  font-weight: 600;
}



.form-check-input:checked {
  background-color: #ffffff;
  border-color: #f21000;
  border-width: 5px;
}
.form-check-input:not(:disabled):checked {
  box-shadow: 0 2px 4px 0 rgb(225 0 0 / 28%);
}


.cus-p-1 {
  padding: 5px 5px 5px 5px;
}

@media only screen and (min-width: 600px) {
  .justify-content-center {
    justify-content: end !important;
  }
}

.card-footer {
  padding: 10px 10px 0px 10px;
}
</style>
